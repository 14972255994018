.viz-text {
  margin: 0 auto 10px auto;
  width: 580px;
}

  .viz-text-hdr {
    font-size: 16px;
    margin: 0;
  }

  .viz-text-desc {
    margin-top: 10px;
  }

.fallback-viz-contain {
  display: none;
}

.viz-toggle-spin {
  display: none;
}
