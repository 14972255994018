// Official EOL colors (kind of... sloppy picker):

$eol-black: #272727 !default
$eol-logo-green: #c1d72e !default
$eol-green: rgb(192, 213, 48) !default
$eol-light-green: #99cc33 !default
$eol-blue: #91bde5 !default
$eol-dark-blue: #426c96 !default
$eol-red: #993333 !default
$eol-gray: #666666 !default
$eol-green: #447766 !default
$eol-orange: #d59046 !default
$eol-brown: #784848 !default
$eol-hier-yellow: #fefce5 !default
$eol-hier-orange: #c06a11 !default
$white: #fff !default
$black: #000 !default
$light-blue: #dfebf1 !default
$light-gray: #ddd !default
$eol-logo-blue: #336699 !default
$link-color: #4789ca !default
$light-bg-blue: #99cdef !default
$navbar-light-gray: #f8f8f8 !default
$hover-gray: #ccc !default


$nav-dark-gray: #999 !default
$nav-hover-gray: #666 !default

